var colorContrast = {};

function sRGBtoLin(colorChannel) {
  if (colorChannel <= 0.04045) {
    return colorChannel / 12.92;
  } else {
    return Math.pow(((parseFloat(colorChannel) + 0.055) / 1.055), 2.4);
  }
}


colorContrast.hexToRGB = function (hex) {
  hex = hex.length < 7 ? hex + hex.slice(1, 4) : hex;
  const r = parseInt(hex.slice(1, 3), 16) / 255
  const g = parseInt(hex.slice(3, 5), 16) / 255
  const b = parseInt(hex.slice(5, 7), 16) / 255
  // return {r, g, b} // return an object
  return [r, g, b]
}
colorContrast.getContrast = function (rgb) {
  // check if we are receiving an element or element background-color
  if (rgb instanceof jQuery) {
    // get element background-color
    rgb = rgb.css('background-color');
  } else if (rgb.indexOf('#') === 0) {
    rgb = this.hexToRGB(rgb).join(',');
  } else if (typeof rgb === 'string') {
    // Strip everything except the integers eg. "rgb(" and ")" and " "
    rgb = rgb.split(/\(([^)]+)\)/)[1].replace(/ /g, '');
  } else if (typeof rgb !== 'string') {
    return;
  }

  // map RGB values to variables
  var r = rgb.split(',')[0],
    g = rgb.split(',')[1],
    b = rgb.split(',')[2];

  var luminence = (0.2126 * sRGBtoLin(r) + 0.7152 * sRGBtoLin(g) + 0.0722 * sRGBtoLin(b))

  var backgroundLuminence = (0.2126 * 1 + 0.7152 * 1 + 0.0722 * 1)

  var darkestColor = luminence < backgroundLuminence ? luminence : backgroundLuminence;
  var lightestColor = luminence <= backgroundLuminence ? backgroundLuminence : luminence;

  return (lightestColor + 0.05) / (darkestColor + 0.05);
};

colorContrast.setColorContrast = function() {
  var $contrastElements = $('.js-product-brief-shades-item-link, .js-spp-shades-grid-item').not('.low-contrast');
  if ($contrastElements.length < 1) {
    return;
  }
  $contrastElements.each(function () {
    var $this = $(this);
    var currentShade = $this.data('hex');
    if (!currentShade) {
      return;
    }
    contrast = colorContrast.getContrast(currentShade);
    $this.attr('data-contrast', contrast);
    if (contrast < 4.51) {
      $this.addClass('low-contrast').attr('data-color-contrast', contrast);
    }
  });
}
colorContrast.setColorContrast();

$(document).on('productBriefShades.init', function () {
  colorContrast.setColorContrast();
});